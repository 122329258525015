<template>
  <div class="fish">
    <div class="Resoure_pic">
      <el-image class="picing" :src="transaction.img"></el-image>
      <span class="name">{{ this.$store.state.userinfo.name }}</span>
      <el-button type="primary" @click="down"
        ><i class="el-icon-upload el-icon--right"></i
      ></el-button>
    </div>
    <div class="content">
      <div class="zc">
        <div class="zc_x">
          <img class="score" src="@/assets/icon/downla.png" />
          发布资源<span class="down">{{ dl }}</span>
        </div>
        <div class="zc_x">
          <img class="score" src="@/assets/icon/Gold1.png" />
          可用知识币 <span class="down">{{ Money }}</span>
        </div>
      </div>
      <div class="resour">
        <div class="locking_top">
          <span v-show="ts_1"
            ><img
              class="score"
              src="@/assets/icon/Collection1.png"
            />该资源正在审核中</span
          >
          <span v-show="ts_2">
            <img
              class="score"
              src="@/assets/icon/Collection1.png"
            />该内容审核未通过，请修改后重新提交</span
          >
          <span v-show="ts_3">
            <img
              class="score"
              src="@/assets/icon/Collection1.png"
            />恭喜！该内容审核通过</span
          >
        </div>
        <div class="sh">
          <div class="locking_bottom">
            <el-image :src="this.img"></el-image>
            <div class="sss">
              <div>{{ this.name }}</div>
              <span>{{ this.UserauthorName }}上传</span>
              <span style="margin-left:10px">{{ this.size }}M</span>
            </div>
          </div>
          <div class="locking_center">
            资源分类:<span>{{ this.classification }}</span> <br />
            资源大小:<span>{{ this.size }}M</span>
          </div>

          <div class="bq">
            <span v-for="item in list" :key="item">{{ item }}&emsp;</span>
          </div>
          <span class="pass" v-show="ts_1">资源审核中</span>
          <span class="pass" v-show="ts_2">审核未通过</span>
          <span class="pass" v-show="ts_3">审核已通过</span>
        </div>
      </div>
      <div class="tz" v-show="ts_1">
        <div class="tz_top">
          <span
            ><img
              class="score"
              src="@/assets/icon/Collection1.png"
            />该资源正在审核中</span
          >
        </div>
        <div class="tz_l">
          您发布的资源{{ this.name }}已提交审核，
          文档通过审核后，即可收到消息提示，请耐心等待.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataFormat } from "@/js/common/index.js";
import { INSERT, QUERY, DELETE } from "@/services/dao.js";
export default {
  name: "index",
  data() {
    return {
      transaction: {
        img: require("@/assets/timg (9).png"),
        name: "aaa",
        zhishi: "10",
        download: "200",
      },
      Money: 0,
      dl: "",
      img: require("@/assets/img/WORD.png"),
      name: "利用python进行数据分析",
      UserauthorName: "",
      score: "4.5",
      read: "3w",
      download: "590",
      time: "2020-6-3",
      page: "14",
      size: "0",
      classification: "",
      url:
        "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2594104736,4220653550&fm=26&gp=0.jpg",
      Price: "30",
      content:
        "这种用整数表示的方法称为分类或字典编码表示法。不同值得数组称为分类、字典或数据级。本书中，我们使用分类的说法。表示分类的整数值称为分类编码或简单地称为编码。分类表示可以在进行分析时大大的提高性能。你也可以在保持编码不变的情况下，对分类进行转换。一些相对简单的转变例子包括重命名分类。加入一个新的分类，不改变已经存在的分类的顺序或位置。pandas的分类类型pandas有一个特殊的分类类型，用于保存使用整数分类表示法的数据。看一个之前的Series例子：",
      list: [],
      Collection: "2",
      comment: "3",
      circleurl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      activeName: "first",
      ts_1: true,
      ts_2: false,
      ts_3: false,
    };
  },
  created() {
    this.$route.query.id;
    this.name = this.$route.query.name;
    this.time = this.$route.query.createTime;
    this.classification = this.$route.query.classification;
    this.init();
  },
  destroyed() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async init() {
      let res = await QUERY(
        "post",
        "",
        'ZYDocument_ALL( where: {id: {_eq: "' +
          this.$route.query.id +
          '"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id   name format read download UserauthorName classification Febulous score  score  Price Time  state keyword}"
      );
      let info = await QUERY(
        "post",
        "",
        "  PCUsers(where: {id: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     Money  img  }"
      );
      switch (res.data.ZYDocument_ALL[0].format) {
        case "docx":
          this.img = require("@/assets/icon/WORD@2x.png");
          break;
        case "txt":
          this.img = require("@/assets/icon/txt(1)@2x.png");
          break;
        case "pdf":
          this.img = require("@/assets/icon/PDF48@2x.png");
          break;
        case "excel":
          this.img = require("@/assets/icon/excel(2) 拷贝@2x.png");
          break;
        case "ppt":
          this.img = require("@/assets/icon/ppt@2x.png");
          break;
        default:
          this.img = require("@/assets/icon/？@2x.png");
      }
      this.Money = info.data.PCUsers[0].Money;
      this.transaction.img = this.api.LoginURL.concat(info.data.PCUsers[0].img);
      let res1 = await QUERY(
        "post",
        "",
        'ZYDocument_ALL( where: {user: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id     }"
      );
      this.dl = res1.data.ZYDocument_ALL.length;
      this.size = res.data.ZYDocument_ALL[0].Febulous;
      this.name = res.data.ZYDocument_ALL[0].name;
      this.time = res.data.ZYDocument_ALL[0].createTime;
      this.UserauthorName = res.data.ZYDocument_ALL[0].UserauthorName;
      this.list = eval(res.data.ZYDocument_ALL[0].keyword);

      this.classification = res.data.ZYDocument_ALL[0].classification;
      if (res.data.ZYDocument_ALL[0].state == "1") {
        this.ts_3 = false;
        this.ts_2 = false;
        this.ts_1 = true;
      }
      if (res.data.ZYDocument_ALL[0].state == "2") {
        this.ts_3 = false;
        this.ts_2 = true;
        this.ts_1 = false;
      }
      if (res.data.ZYDocument_ALL[0].state == "3") {
        this.ts_3 = true;
        this.ts_2 = false;
        this.ts_1 = false;
      }
    },
    down() {
      this.$router.push({ name: "mydownload" });
    },
  },
};
</script>

<style scoped>
.fish {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 30px 161px 30px 300px;
}
.Resoure_pic {
  width: 173px;
  background-color: #ffffff;
  /* overflow: hidden; */
  float: left;
  text-align: center;
  margin-left: 50px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.picing {
  width: 110px;
  height: 110px;
  border-radius: 999px;
  margin: 33px 33px 0px 33px;
}
.name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  line-height: 50px;
  width: 100%;
}
.Resoure_pic button {
  width: 100%;
}
.Resoure_list {
  float: left;
  width: 765px;
  background-color: #ffffff;
  margin-left: 2px;
}
.content {
  float: left;
  width: 70%;
  background-color: #ffffff;
}
.el-menu-item {
  margin-left: 150px;
  margin-right: 150px;
}
.el-menu-demo {
  border: 1px solid #f7f7f7;
}
.resour {
  margin: 10px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.zc {
  width: 100%;
  overflow: hidden;
}
.zc img {
  width: 14px;
}
.zc_x {
  float: right;
  margin: 5px 30px 0px 10px;
}
.el-form {
  margin: 30px;
}
.bq span {
  border: 1px solid #f7f7f7;
  padding: 5px;
  border-radius: 30px;
  margin: 0px 10px 0px 0px;
}
.locking_top {
  text-align: center;
  background-color: rgba(210, 210, 210, 1);
  line-height: 18px;
}
.locking_top span img {
  width: 18px;
}
.sh {
  padding: 20px;
}
.locking_bottom {
  width: 100%;
  overflow: hidden;
}
.locking_bottom .el-image {
  width: 28px;
  float: left;
}
.sss {
  float: left;
  width: 90%;
  margin-left: 2%;
}
.sss img {
  width: 14px;
  margin-left: 40px;
}
.locking_center {
  margin-top: 20px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}
.edit {
  line-height: 30px;
  color: #0084ff;
}
.edit img {
  width: 14px;
}
.bq {
  margin-top: 20px;
}
.tz {
  margin: 50px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.tz_l {
  margin: 30px;
}
.tz_top {
  background-color: rgba(210, 210, 210, 1);
  line-height: 30px;
  /* margin-left: 20px; */
  padding-left: 20px;
}
.tz_top img {
  width: 14px;
}
.pass {
  margin-top: -20px;
  float: right;
  color: #0084ff;
  border: 1px solid #0084ff;
  padding: 5px;
  border-radius: 5px;
}
</style>
